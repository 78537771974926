.layout_container {
    .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
        height: 1px;
        padding-left: 1rem;
    }

    .css-1ixds2g {
        height: 1px;
        padding-left: 1rem;
    }
}
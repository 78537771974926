.responsive_img {
    @media (max-width: 330px) {
        width: 50%;
    }
}

.input_border {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: 2px solid #939393;
    appearance: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-radius: 2rem;
}

.grid_border {
    .css-ekndaq-MuiDataGrid-root {
        border: none;
    }
}

.delete_modal {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        @media (min-width: 768px) {
            min-width: 35rem;
        }
    }
}

.invitation_modal {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        @media (min-width: 768px) {
            max-width: 25rem;
        }
    }
}

.delete_modal_invite {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        @media (min-width: 768px) {
            min-width: 25rem;
        }
    }
}

.custome_create_col {
    @media (max-width: 639px) {
        display: flex;
        flex-direction: column;
    }
}

.custom_font {
    @media (max-width: 639px) {
        font-size: 1.5rem;
    }
}

.MuiDataGrid-cellContent {
    font-size: 16px;
    font-weight: 400;
    color: #191D23;
    line-height: 20px;
}

.select {
    .css-3w2yfm-ValueContainer {
        max-width: 39rem;
    }

    .css-1dyz3mf {
        max-width: 39rem;
    }

    .css-wsp0cs-MultiValueGeneric {
        width: 12rem;
    }

    .css-9jq23d {
        width: 12rem;
    }
}